import { forwardRef } from "react";

const MyLink = (props: any, ref: any) => {
  let { children, active, onClick, ...rest } = props;
  return (
      <button
          onClick={()=> onClick()}
        {...rest}
        className={`${
          active ? "bg-gray-500 rounded-md text-white" : "text-gray-900"
        } group flex w-full items-center  px-2 py-2 text-sm`}
      >
        {children}
      </button>
  );
};

export default forwardRef(MyLink);