import React, {useState} from "react";
import { format } from "date-fns";
import { DayPicker, SelectSingleEventHandler } from "react-day-picker";
import "react-day-picker/dist/style.css";
import {DatePickerProps} from "../../shared/date-picker-props";

const DatePicker = (
    {
        label,
    initialState = new Date(),
    onChange
    }: DatePickerProps) => {
    const [selectedDay, setSelectedDay] = useState<Date>(initialState);
    const formattedDate = format(selectedDay, "MMMM d, yyyy");
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  
  const handleSelect: SelectSingleEventHandler = (day) => {
    if (day) {
      if (day.valueOf() !== selectedDay.valueOf()) {
        setSelectedDay(() => day as Date);
        onChange(day);
      }
    }
    setIsDatePickerVisible(false);
  };

  const toggleDatePickerVisibility = () => {
    setIsDatePickerVisible(!isDatePickerVisible);
  };
  
  return (
    
    <div className="relative mr-3 ">
      <style>
        {`
          .rdp-day_selected {
            background-color: #1e293b !important;
            color: white !important;
          }
          
          .rdp-button:hover {
            background-color: #e2e8f0 !important;
          }
        `}
      </style>
      <label
        htmlFor="start-date-input"
        className="absolute z-10 text-xs font-medium text-gray-600 transform bg-white -translate-x-1/3 -translate-y-1/3 left-8 bottom-6"
      >
          {label}
      </label>

      <input
        id="start-date-input"
        onClick={toggleDatePickerVisibility}
        value={formattedDate}
        readOnly
        className="block w-full p-2 pl-3 pr-8 mt-4 text-xs text-left border rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
      />
      {isDatePickerVisible && (
        <div className="absolute z-10 mt-1">
          <div className="bg-white border border-gray-300 rounded-lg shadow-md">
            <DayPicker
              mode="single"
              selected={selectedDay}
              onSelect={handleSelect}
              defaultMonth={selectedDay} // Add this prop to show the selected month
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
