import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  getCompanies
} from "../../../shared/services/internal-user-service";

type EditModalProps = {
  isOpen: boolean;
  data: any;
  onClose: () => void;
};

const EditModal = ({ isOpen, data, onClose, fetchData }: EditModalProps & { fetchData: () => void }) => {
  const [formData, setFormData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState<number>(0);
  const [companies, setCompanies] = useState<any[]>([]);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const [ companyResponse] = await Promise.all([
       
          getCompanies(),
        ]);
        setCompanies(companyResponse);
        
      } catch (err) {
        console.error(err);
      }
    };

    fetchCompanies();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response =  await axios.put(
        `/api/Satellite/${formData.satelliteId}`,
        formData
      );      
      onClose(); // Close the modal after successful submission
      fetchData(); // Refresh the data after successful submission
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  if (!isOpen || !data) return null;

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto" role="dialog">
      <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <form
          className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          onSubmit={handleSubmit}
        >
          <h3
            className="text-lg font-bold leading-6 text-center text-gray-900"
            id="modal-headline"
          >
            Update a Satellite
          </h3>
          <div className="mt-3 text-left sm:mt-5">
            <legend className="text-sm font-bold">General Information</legend>
            <hr className="mt-2 mb-3 border-gray-300" />
            <label>
              Company:
              <input
                 name="company.companyName" 
                value={formData?.company?.companyName || ""}
                onChange={handleChange}
                className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
              />
            </label>

            <label>
              Satellite Code:
              <input
                name="satelliteCode" 
                value={formData?.satelliteCode || ""}
                onChange={handleChange}
                className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
              />
            </label>
            <label>
              Satellite Name:
              <input
                name="satelliteName"
                value={formData?.satelliteName || ""}
                onChange={handleChange}
                className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
              />
            </label>
            <label>
              Internal Name:
              <input
                name="internalName"
                value={formData?.internalName || ""}
                onChange={handleChange}
                className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
              />
            </label>
            <legend className="mt-5 text-sm font-bold">Reporting Groups</legend>
            <hr className="mt-2 mb-3 border-gray-300" />
            <label>
              Group Name:
              <input
                name="groupName"
                value={formData?.groupName || ""}
                onChange={handleChange}
                className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
              />
            </label>
            <label>
              Sub Group Name:
              <input
                 name="subGroupName"
                value={formData?.subGroupName || ""}
                onChange={handleChange}
                className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
              />
            </label>
          </div>
          <div className="mt-5 sm:mt-6">
            <span className="flex w-full rounded-md shadow-sm">
              <button
                type="button"
                className="inline-flex justify-center w-full px-4 py-2 mr-2 text-base font-medium leading-6 text-black transition duration-150 ease-in-out border border-transparent rounded-md shadow-sm bg-slate-200 hover:bg-slate-300 focus:outline-none focus:border-slate-500 focus:shadow-outline-red sm:text-sm sm:leading-5"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
      type="submit"
      disabled={isLoading}
      className={`inline-flex justify-center w-full px-4 py-2 mx-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm sm:text-sm ${
        isLoading
          ? "bg-sky-500"
          : "bg-sky-600 hover:bg-sky-700 focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
      }`}
    >
      {isLoading ? (
        <>
          <svg
            className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Loading...
        </>
      ) : (
        "Submit"
      )}
    </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditModal;
