import axios, {AxiosError} from "axios";
import {getHeadersWithToken} from "./session-service";
import {ServiceexpertsAuthenticationRequest} from "../../models/serviceexperts-authentication-request";
import {HomevestorsAuthenticationRequest} from "../../models/homevestors-authentication-request";
import {Survey} from "../../models/survey";
import {SurveyCompletionResponse} from "../../models/survey-completion-response";
import {AuthenticationResponse} from "../../models/authentication-response";import {get, post} from "../helpers/Api";

const controllerName = '/api/survey';

export const authenticateServiceExperts = async (data: ServiceexpertsAuthenticationRequest) =>{
    return await post<AuthenticationResponse>(`${controllerName}/serviceexperts/authentication`,
        data);
}

export const authenticateHomevestors = async (data: HomevestorsAuthenticationRequest) =>{
    return await post<AuthenticationResponse>(`${controllerName}/homevestors/authentication`,
        data);
}

export const getSurveyWithDetails = async () =>{
    return await get<Survey>(`${controllerName}`);
}

export const completeSurvey = async () =>{
    return await post<SurveyCompletionResponse>(`${controllerName}/completion`, {});
}

export const getSurveyHtml = async (respondentId:number, companyId:number, satelliteId:number) =>{
    return await get<string>(`${controllerName}/html?respondentId=${respondentId}&companyId=${companyId}&satelliteId=${satelliteId}`);
}