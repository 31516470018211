import HomevestorsLayout from "../layouts/homevestors-layout";
import HvForm from "../components/Survey/HvForm";
import { useEffect } from 'react';

const Homevestors =()=>{
    useEffect(() => {
        document.title = 'HomeVestors® | Customer Satisfaction Survey';
      }, []);
    return(
        <HomevestorsLayout>
            <HvForm/>
        </HomevestorsLayout>
    )    
}
export default Homevestors;
