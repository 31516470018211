import { createContext } from "react";

export interface SatelliteContextType {
  selectedSatelliteId: number;
  setSelectedSatelliteId: (value: number) => void;
  selectedCompanyId: number;
  setSelectedCompanyId: (value: number) => void;
}

export const SatelliteContext = createContext<SatelliteContextType>({
  selectedSatelliteId: 0,
  setSelectedSatelliteId: () => {},
  selectedCompanyId: 0,
  setSelectedCompanyId: () => {},
});
