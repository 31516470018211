import jwt from 'jwt-decode'
import {User} from "../../models/user";

const commendTokenName = 'commend-token';

export const isAuthenticated = (systemUserType: string) =>{
    const token = getToken();
    if(token){
        const user= jwt<User>(token);
        const currentTime = new Date();
        const userExpiration = new Date(user.exp*1000);  
        if(userExpiration < currentTime){
            return false;
        }
        return systemUserType === user.systemUserType;        
    }
    return false;
}

export const getToken = () => {
    return localStorage.getItem(commendTokenName);
}

export const setToken = (token: string) => {
    localStorage.setItem(commendTokenName, token);
}

export const removeToken = () => {
    localStorage.removeItem(commendTokenName);
}

export const getHeadersWithToken = () =>{
    return {
        headers: {
            Authorization: `bearer ${getToken()}`,
        },
    };
}

export const logout = () =>{
    localStorage.removeItem(commendTokenName);
    //return to 
}

export const getCurrentUser = () =>{
    const token = getToken();
    if(token){
        return jwt<User>(token);
    }else{
        return null;
    }
    
}

export const setSubCenter = (subCenter: string) =>{
    localStorage.setItem('sub-center',subCenter);
}
export const getSubCenter = () =>{
    return localStorage.getItem('sub-center');
}

export const deleteSubCenter = () =>{
    return localStorage.removeItem('sub-center');
}