import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "src/components/Admin/Header";
import AddSatelliteModal from "src/components/Admin/Satellites/AddSatelliteModal";
import SatellitesTable from "src/components/Admin/Satellites/SatellitesTable";
import { USER_TYPE_INTERNAL } from "src/models/user";
import {
  getCurrentUser,
  isAuthenticated,
} from "../../../../src/shared/services/session-service";
import { SatelliteProvider } from "../SatelliteProvider";

const Satellites = () => {
  const [selectedId, setselectedId] = useState(0);
  const [selectedCompanyId, setSelectedCompanyId] = useState(0);
  const user = getCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();

  const changeNewCompanyId = (value: any) => {
    setSelectedCompanyId(value);
    setselectedId(0); // reset selected satellite id every time company id changes
  };

  const changeNewId = (value: any) => {
    setselectedId(value);
  };

  const userId = user?.userId;
  const clientId = user?.clientId;

  useEffect(() => {
    if (location.pathname !== "/admin/satellites") {
      navigate("/admin/satellites");
    }
  }, [location, navigate]);

  useEffect(() => {
    if (!isAuthenticated(USER_TYPE_INTERNAL)) {
      navigate("/admin");
    }
  }, []);
  useEffect(() => {
    setselectedId(0);
  }, [selectedCompanyId]);

  return (
    <>
      <SatelliteProvider>
        <Header
          changeSatelliteId={changeNewId}
          changeCompanyId={changeNewCompanyId}
        />
        <div className="flex items-center justify-between mx-4 mt-4 mb-2">
          <h2 className="text-xl font-semibold sm:text-2xl text-slate-700">
            Satellites
          </h2>
          <AddSatelliteModal userId={userId} />
        </div>
        <div className="mx-4">
          <SatellitesTable
            companyId={selectedCompanyId}
            satelliteId={selectedId}
            clientId={clientId}
          />
        </div>
      </SatelliteProvider>
    </>
  );
};

export default Satellites;
