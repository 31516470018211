import { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LoadingButton from "./LoadingButton";
import { authenticateInternalUser } from "../../shared/services/internal-user-service";
import { HttpRequestError } from "../../models/http-request-error";
import { setToken } from "../../shared/services/session-service";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const swal = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e: { target: { name: any; value: any } }) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const authenticationResponse = await authenticateInternalUser({
        username: credentials.username,
        password: credentials.password,
      });
      setToken(authenticationResponse.token);
      navigate("/admin/dashboard");
    } catch (error) {
      if (error instanceof HttpRequestError) {
        swal.fire({
          text: error.message || "Unexpected error. Please try again.",
          toast: true,
          icon: "error",
          position: "top-right",
          timer: 3000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="container flex-col justify-center mx-auto my-0 mb-20 md:max-w-2xl">
        <form
          className="px-10 py-5 mx-5 mb-5 bg-white rounded-lg "
          onSubmit={handleSubmit}
        >
          <label
            className="block text-lg font-bold text-gray-600 "
            htmlFor="username"
          >
            Username
          </label>
          <input
            type="username"
            id="username"
            name="username"
            placeholder="username"
            className="w-full p-3 mt-3 border rounded-xl bg-gray-50"
            onChange={handleChange}
          />

          <label
            className="block text-lg font-bold text-gray-600"
            htmlFor="password"
          >
            Password
          </label>
          <div className="relative">
            <input
              placeholder="Password"
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              className="w-full p-3 mt-3 mb-4 border rounded-xl bg-gray-50"
              onChange={handleChange}
            />
            <button
              type="button"
              className="absolute text-sm font-semibold text-gray-500 top-6 right-5 hover:text-gray-700"
              onClick={toggleShowPassword}
            >
              {showPassword ? "Hide" : "Show"}
            </button>
          </div>

          <LoadingButton
            buttonText="Submit"
            value="Next"
            isLoading={isLoading}
          />
        </form>
      </div>
    </>
  );
};

export default LoginForm;
