import {useEffect, useState} from "react";
import Layout from "src/components/Admin/Layout";
import {DashboardContext} from "../admin-context";
import * as jose from 'jose'
import {isAuthenticated} from "../../../shared/services/session-service";
import {useNavigate} from "react-router-dom";
import {USER_TYPE_INTERNAL} from "../../../models/user";
import {format} from "date-fns";



const METABASE_SITE_URL = "https://metabase.imag-infra.net";
const METABASE_SECRET_KEY = "377b901eab419b0b9ab8d0ab19bfc307f9aa680f016411fea753fe4bab80a736";

const Dashboard = () => {
  const navigate = useNavigate();
  const [iframeUrl, setIframeUrl] = useState("");
  const [clientId, setClientId] = useState<number>(0);
  const [companyId, setCompanyId] = useState<number>(0);
  const [satelliteId, setSatelliteId] = useState<number>(0);
  const [dashboardId, setDashboardId] = useState<number>(37);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const updateDashboardParameters = (clientId: number,
                            companyId: number,
                            satelliteId: number,
                                     startDate: Date,
                                     endDate: Date) => {
    setClientId(clientId);
    setCompanyId(companyId);
    setSatelliteId(satelliteId);
    setStartDate(startDate);
    setEndDate(endDate);
    renderDashboard(
        dashboardId,
        clientId,
        companyId,
        satelliteId,
        startDate,
        endDate
    );
  }
  const renderDashboard = async (
      dashboardId: number,
      clientId: number,
      companyId: number,
      satelliteId: number,
      startDate: Date,
      endDate: Date
  ) =>{
   
    const payload = {
      resource: { dashboard: dashboardId },
      params: {
        "client_id": clientId.toString(),
        "satellite_id": satelliteId.toString(),
        "company_id": companyId.toString(),
        "start_date": format(startDate, "yyyy-MM-dd"),
        "end_date": format(endDate, "yyyy-MM-dd")
      },
      exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
    };
    const secret = new TextEncoder().encode(
        METABASE_SECRET_KEY,
    )
    const alg = 'HS256'
    const token = await new jose.SignJWT(payload)
        .setProtectedHeader({ alg })
        .sign(secret);
    setIframeUrl(
        `${METABASE_SITE_URL}/embed/dashboard/${token}#bordered=false&titled=true`
    );
  }
  const updateDashboardId = (dashboardId: number) => {
    setDashboardId(dashboardId);
    renderDashboard(
        dashboardId,
        clientId,
        companyId,
        satelliteId,
        startDate,
        endDate
    );
  }
  
  useEffect(()=>{
    if(!isAuthenticated(USER_TYPE_INTERNAL)){
      navigate('/admin');
    }
  })


  
  return (
      <DashboardContext.Provider  value={
        {
          updateDashboardId: updateDashboardId,
          updateDashboardParameters: updateDashboardParameters
        }        
      }>
        <Layout>
          <iframe
              title="Metabase Embed"
              src={iframeUrl}
              width="100%"
              style={{
                // borderRadius: '5px',
                height: '98vh',
              }}
          />
        </Layout>
      </DashboardContext.Provider>      
  );
};

export default Dashboard;
