import { useEffect } from "react";
import { Link } from "react-router-dom";
import hvwbLogos from "src/images/homevestors/hvwb-logos.svg";
import commendLogo from "src/images/homevestors/commend-logo.svg";

const HomevestorsLayout = ({ children }: any) => {
    useEffect(() => {
        require('src/styles/homevestors.css');
        document.body.classList.add('body-homevestors');

        return () => {
            document.body.classList.remove('body-homevestors');
        };
    }, []);

    return (
        <>
            <div className="px-2.5 py-11">
                <div className="px-2.5">
                    <img className="mx-auto mb-8 w-[23.375rem]"
                        src={hvwbLogos}
                        alt="HomeVestors® + We Buy Ugly Houses®"
                        width="374"
                    />
                </div>
                <div className="mx-auto mb-8 max-w-[37.5rem] px-4 sm:px-14 py-7 bg-white rounded border border-[#8FD7D4]">
                    {children}
                </div>
                <div>
                <img className="mx-auto mb-24 w-[10.0625rem]"
                        src={commendLogo}
                        alt="Powered by Commend"
                        width="161"
                        height="43"
                    />
                </div>
            </div>
            <footer className="fixed bottom-0 left-0 right-0 py-4 text-sm leading-tight text-white bg-[#514D4D] text-center">
                <div>
                    {/* the spans help with wrapping at specific points on mobile */}
                    <span className="inline-block">&copy;{new Date().getFullYear()} HomeVestors of America Inc.</span>
                    &nbsp;
                    <span className="inline-block">All rights reserved.</span>
                    &nbsp;
                    <Link to="https://www.homevestors.com/privacy-policy/" target="_blank" className="underline">Privacy Policy</Link>
                </div>
                <div className="mt-1">
                    <span className="inline-block">Commend is a registered trademark of Digital Touchpoint, Inc.</span>
                </div>
            </footer>
        </>
    );
};
export default HomevestorsLayout;
