import { Link } from "react-router-dom";
import fblogo from "src/images/fblogo.png";
import glogo from "src/images/glogo.png";
import { saveRespondentReview } from "../../shared/services/respondent-review-service";
import { useEffect, useState } from "react";
import { getSatelliteReviewSite } from "../../shared/services/satellite-review-site-service";
const TyHvSimple = () => {
    const [googlePlacesUrl, setGooglePlacesUrl] = useState<string>();
    const [googlePlacesReviewSiteId, setGooglePlacesReviewSiteId] = useState<number>();
    const [facebookUrl, setFacebookUrl] = useState<string>();
    const [facebookReviewSiteId, setFacebookReviewSiteId] = useState<number>();
    const registerClick = async (reviewSiteId: number) => {
        await saveRespondentReview({ reviewSiteId: reviewSiteId });
    }

    const fetchSatelliteReviewSites = () => {
        getSatelliteReviewSite('Google Places')
            .then(response => {
                setGooglePlacesUrl(response.reviewLink);
                setGooglePlacesReviewSiteId(response.reviewSiteId);
            });

        getSatelliteReviewSite('Facebook')
            .then(response => {
                setFacebookUrl(response.reviewLink);
                setFacebookReviewSiteId(response.reviewSiteId);
            });
    }

    useEffect(() => {
        fetchSatelliteReviewSites();
    }, [])

    return (
        <>
            <h2 className="mb-4 text-[1.375rem] leading-8 font-bold text-black">
                Thank you for your feedback! Please review HomeVestors<sup>&reg;</sup>, the We Buy Ugly Houses<sup>&reg;</sup> people, online.
            </h2>
            <p className="mb-4 text-black">
                Your insights help ensure they meet the highest standard of customer service.
            </p>
            <p className="mb-4 text-black">
                Should you find a moment, please share how you feel about your selling experience online. Your reviews and comments can help others who may be in the same situation that you were in.
            </p>
            <p className="mb-4 text-black">
                Once again, thank you for choosing HomeVestors<sup>&reg;</sup>, the We Buy Ugly Houses<sup>&reg;</sup> people. Your feedback plays a crucial role in their ongoing efforts to improve and provide exceptional service.
            </p>
            <div className="mt-6 flex justify-center">
                {(googlePlacesUrl && googlePlacesReviewSiteId) &&
                    <Link to={googlePlacesUrl} onClick={() => registerClick(googlePlacesReviewSiteId)}>
                        <div className="flex justify-center p-2 px-2.5 bg-[#2089C4] rounded-md">
                            <div className="w-10 bg-white rounded-full p-1 mr-1.5">
                                <img src={glogo} alt="Google logo" width="986" height="986" className="" />
                            </div>
                            <div className="mt-1 text-lg sm:text-xl font-bold text-white">
                                Leave a Google review
                            </div>
                        </div>
                    </Link>
                }

                {(facebookUrl && facebookReviewSiteId) &&
                    <Link to={facebookUrl} target={"_blank"} onClick={() => registerClick(facebookReviewSiteId)}>
                        <div className="px-5">
                            <img src={fblogo} alt="Facebook logo" width="70px" height="100%" />
                            <div className="mt-1 mb-2 text-lg font-bold text-center text-gray-700">
                                Leave a Facebook review
                            </div>
                        </div>
                    </Link>
                }
            </div>
        </>
    );
};

export default TyHvSimple;
