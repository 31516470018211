import React, { useState } from "react";

const LoadingButton = ({ buttonText, isLoading }: any) => {
  const [isClicked, setIsClicked] = useState(false);
  function handleClick() {
    // setIsClicked(true);
    // onClick();
  }
  return (
    <>
      <button
        type="submit"
        className={`flex justify-center w-full py-3 font-bold text-white uppercase transition-colors rounded bg-sky-600 hover:cursor-pointer hover:bg-sky-700 ${
        isLoading
            ? "inline-flex items-center px-4 py-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out bg-sky-500 rounded-md shadow cursor-not-allowed bg-sky400"
            : ""
        }`}
      >
        {isLoading && (
          <svg
            className="w-5 h-5 text-white animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        {!isLoading && <span>{buttonText}</span>}
      </button>
    </>
  );
};

export default LoadingButton;
