import { useCallback } from "react";

export interface SurveyOpenItemProps {
    id: string,
    text: string,
    onChange(newValue: string): void
}
let timer: ReturnType<typeof setTimeout>;

const SurveyOpenItem = (props: SurveyOpenItemProps) => {

    const handleChange = useCallback((newValue: string) => {
        clearTimeout(timer);
        timer = setTimeout(() => props.onChange(newValue),
            2000)
    }, [])
    return (
        <div className={"w-full"}>
            <div className={'mb-4 font-sans text-gray-700'}>
                {props.text}
            </div>
            <div className={""}>
                <textarea rows={4} className={'w-full border border-gray-300 rounded-md text-gray-500 px-2'}
                    placeholder="Enter your response"
                    onChange={value => handleChange(value.target.value)}
                ></textarea>
            </div>
        </div>
    );
}


const processText = (text: string) => {
    // convert the (R) to superscript
    return text.replace(/\u00AE/g, '<sup>&reg;</sup>');
}

const SurveyOpenItemHomevestors = (props: SurveyOpenItemProps) => {
    const handleChange = useCallback((newValue: string) => {
        clearTimeout(timer);
        timer = setTimeout(() => props.onChange(newValue), 2000)
    }, [])

    return (
        <div>
            <p
                className="mb-3 text-black"
                dangerouslySetInnerHTML={{ __html: processText(props.text) }}
            />

            <div>
                <textarea
                    rows={4}
                    className="w-full p-2.5 border rounded bg-[#F2F5F7] font-light focus:border-[#2089C4] focus-within:bg-white focus:text-black focus:font-normal"
                    placeholder="Enter your response"
                    onChange={value => handleChange(value.target.value)}
                />
            </div>
        </div>
    );
}

export { SurveyOpenItem, SurveyOpenItemHomevestors };
export default SurveyOpenItem;
