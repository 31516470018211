import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./app";
import ReactGA from 'react-ga';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const TRACKING_ID = 'UA-41544088-5';
ReactGA.initialize(
    [
        {
            trackingId: 'UA-41544088-5',
        },
        {
            trackingId: 'UA-41544088-1',
            gaOptions: { name: 'masterTracker' }
        },
        {
            trackingId: 'UA-41544088-2'
        },
        {
            trackingId: 'UA-41544088-4',
            gaOptions: { name: 'four' }
        }
    ],
    { debug: false, alwaysSendToDefaultTracker: false }
);
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

