import {get, post} from "../helpers/Api";
import {InternalUserAuthenticationRequest} from "../../models/internal-user-authentication-request";
import {InternalUserAuthenticationResponse} from "../../models/internal-user-authentication-response";
import {Option} from "../../models/option";

const controllerName = '/api/internaluser';

export const authenticateInternalUser = async (data: InternalUserAuthenticationRequest) =>{
    return await post<InternalUserAuthenticationResponse>(`${controllerName}/authentication`
    , data);
}

export const getClients = async () =>{
    return await get<Option<number>[]>(`/api/client`);
}

export const getCompanies = async () =>{
    return await get<Option<number>[]>(`/api/company`);
}

export const getSatellites = async (companyId: number) =>{
    return await get<Option<number>[]>(`/api/satellite?companyId=${companyId}`);
}