import React, { useEffect, useState } from "react";
import axios from "axios";

const EditNotificationModal = ({
  isOpen,
  data,
  onClose,
  onUpdate,
  userId,
  companyId,
  itemsPerPage,
  currentPage,
  sort,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [satelliteCodes, setSatelliteCodes] = useState([]);

  const [formData, setFormData] = useState(() => ({
    satelliteNotificationId: data?.satelliteNotificationId || 0,
    satelliteId: data?.satelliteId || 0,
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    emailAddress: data?.emailAddress || "",
    isPositiveNotification: data?.isPositiveNotification || false,
    isActive: data?.isActive || false,
    percentBelow: data?.percentBelow || 0,
    notificationRegion: data?.notificationRegion || "",
    notificationTitle: data?.notificationTitle || "",
    satelliteCode: data?.satelliteCode || "",
    userId: userId || "",
  }));

  // Update formData whenever data changes
  useEffect(() => {
    setFormData({
      satelliteNotificationId: data?.satelliteNotificationId || 0,
      satelliteId: data?.satelliteId || 0,
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      emailAddress: data?.emailAddress || "",
      isPositiveNotification: data?.isPositiveNotification || false,
      isActive: data?.isActive || false,
      percentBelow: data?.percentBelow || 0,
      notificationRegion: data?.notificationRegion || "",
      notificationTitle: data?.notificationTitle || "",
      satelliteCode: data?.satelliteCode || "",
      userId: userId || "",
    });
  }, [data, userId]);
  

  useEffect(() => {
    const fetchSatellites = async () => {
      try {
        const response = await axios.get("/api/Satellite/by-company", {
          params: { companyId: companyId },
        });

        // Remove duplicates based on satelliteCode
        const uniqueData = response.data.filter(
          (v: any, i: any, a: any) =>
            a.map((val: any) => val.satelliteCode).indexOf(v.satelliteCode) ===
            i
        );

        // Sort the data based on satelliteCode
        const sortedData = uniqueData.sort(
          (a: { satelliteCode: string }, b: { satelliteCode: string }) =>
            parseInt(a.satelliteCode) > parseInt(b.satelliteCode) ? 1 : -1
        );

        setSatelliteCodes(sortedData); // Here, keep the full data instead of only the codes
      } catch (err) {
        console.error(err);
      }
    };

    fetchSatellites();
  }, []);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.put(
        `/api/SatelliteNotification/${formData.satelliteNotificationId}`,
        formData
      );
      onUpdate();
      onClose();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) {
    return null;
  }


  return (
    <div className="fixed inset-0 z-10 overflow-y-auto" role="dialog">
      <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <div
          className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <h3
            className="mb-3 text-lg font-bold leading-6 text-center text-gray-900"
            id="modal-headline"
          >
            Update a Notification
          </h3>
          <form onSubmit={(e) => handleSubmit(e)}>
            <label>Satellite Code:</label>
            <select
              name="satelliteCode"
              value={formData.satelliteCode}
              onChange={handleSelectChange}
              className="block w-full px-3 py-2 mb-3 leading-5 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-300 focus:shadow-outline-blue sm:text-sm"
            >
              {satelliteCodes.map((satellite: any, index: number) => (
                <option
                  key={`satellite-dropdown-${satellite.satelliteCode}-${index}`}
                  value={satellite.satelliteCode}
                >
                  {`${satellite.satelliteCode} ${satellite.satelliteName}`}
                </option>
              ))}
            </select>
            <label>First Name:</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="block w-full px-3 py-2 mb-3 leading-5 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-300 focus:shadow-outline-blue sm:text-sm"
              placeholder="First Name"
            />
            <label>Last Name:</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="block w-full px-3 py-2 mb-3 leading-5 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-300 focus:shadow-outline-blue sm:text-sm"
              placeholder="Last Name"
            />
            <label>Email:</label>
            <input
              type="email"
              name="emailAddress"
              value={formData.emailAddress}
              onChange={handleChange}
              className="block w-full px-3 py-2 mb-3 leading-5 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-300 focus:shadow-outline-blue sm:text-sm"
              placeholder="Email Address"
            />
            <label>Percent Below:</label>
            <input
              type="number"
              name="percentBelow"
              value={formData.percentBelow}
              onChange={handleChange}
              className="block w-full px-3 py-2 mb-3 leading-5 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-300 focus:shadow-outline-blue sm:text-sm"
              placeholder="Percent Below"
            />
            <label>
              Is Positive Notification
              <input
                type="checkbox"
                name="isPositiveNotification"
                className="ml-2 "
                checked={formData.isPositiveNotification}
                onChange={handleCheckboxChange}
              />
            </label>
            <div className="mt-5 sm:mt-6">
              <span className="flex w-full rounded-md shadow-sm">
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 mr-2 text-base font-medium leading-6 text-black transition duration-150 ease-in-out border border-transparent rounded-md shadow-sm bg-slate-200 hover:bg-slate-300 focus:outline-none focus:border-slate-500 focus:shadow-outline-red sm:text-sm sm:leading-5"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading} // Disable button when loading
                  className={`inline-flex justify-center w-full px-4 py-2 mx-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm sm:text-sm ${
                    isLoading
                      ? "bg-sky-500"
                      : "bg-sky-600 hover:bg-sky-700 focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                  }`}
                >
                  {isLoading ? (
                    <>
                      <svg
                        className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Loading...
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditNotificationModal;
