import axios, {AxiosError} from "axios";
import {AuthenticationResponse} from "../../models/authentication-response";
import {getHeadersWithToken} from "../services/session-service";
import {HttpRequestError} from "../../models/http-request-error";

export const post = async <Output>(url: string, data: any) => {
    try{
        const response = await axios.post<Output>(url,
            data,
            getHeadersWithToken());
        return response.data;
    }catch (ex: any){
        if(ex instanceof  AxiosError){
            throw new HttpRequestError(
                ex.response?.status == 500 ? 'An unexpected error has ocurred. Please, try again later.'
                    : ex.response?.data
            )
        }else{
            throw new HttpRequestError(
                'An unexpected error has ocurred. Please, try again later.'
            )
        }        
    }
}
export const get = async <Output>(url: string) => {
    try{
        const response = await axios.get<Output>(url,
            getHeadersWithToken());
        return response.data;
    }catch (ex: any){
        if(ex instanceof  AxiosError){
            throw new HttpRequestError(
                ex.response?.status == 500 ? 'An unexpected error has ocurred. Please, try again later.'
                    : ex.response?.data
            )
        }else{
            throw new HttpRequestError(
                'An unexpected error has ocurred. Please, try again later.'
            )
        }
    }
} 