import React, { useEffect, useState } from "react";
import axios from "axios";

interface FormData {
  userId: number;
  satelliteCode: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  isPositiveNotification: boolean;
  isActive: boolean;
  percentBelow: number;
  notificationTitle: string;
  satelliteId: number;
}

const initialFormData: FormData = {
  userId: 0,
  satelliteCode: "",
  firstName: "",
  lastName: "",
  emailAddress: "",
  isPositiveNotification: false,
  isActive: true,
  percentBelow: 0,
  notificationTitle: "",
  satelliteId: 0,
};

interface AddNotificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  companyId: any;
  userId: any;
  onAddSatelliteNotification: () => void;
}

const AddSatelliteNotificationModal = ({
  companyId,
  userId,
  isOpen,
  onClose,
  onAddSatelliteNotification,
}: AddNotificationModalProps) => {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [satellites, setSatellites] = useState<
    Array<{ satelliteName: string; satelliteCode: string; satelliteId: number }>
  >([]);
  useEffect(() => {
    setFormData((formData) => ({ ...formData, userId }));
  }, [userId]);

  useEffect(() => {
    const fetchSatellites = async () => {
      try {
        const response = await axios.get("/api/Satellite/by-company", {
          params: { companyId: companyId },
        });

        // Remove duplicates based on satelliteCode
        const uniqueData = response.data.filter(
          (v: any, i: any, a: any) =>
            a.map((val: any) => val.satelliteCode).indexOf(v.satelliteCode) ===
            i
        );

    // Sort the data based on satelliteCode
    const sortedData = uniqueData.sort(
      (a: { satelliteCode: string }, b: { satelliteCode: string }) =>
        parseInt(a.satelliteCode) > parseInt(b.satelliteCode) ? 1 : -1
    );


        setSatellites(sortedData);
      } catch (err) {
        console.error(err);
      }
    };

    fetchSatellites();
  }, []);

  useEffect(() => {
    if (formData.satelliteId) {
      const selectedSatellite = satellites.find((satellite) => satellite.satelliteId === formData.satelliteId);
      if (selectedSatellite) {
        setFormData((prevFormData) => ({ ...prevFormData, satelliteCode: selectedSatellite.satelliteCode }));
      }
    }
  }, [formData.satelliteId]);
  

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    let value: string | number | boolean;

    if (e.target.type === "checkbox") {
      value = (e.target as HTMLInputElement).checked;
    } else {
      value = e.target.value;
    }

    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios
        .post("/api/SatelliteNotification", formData)
        .then();

      if (response.status === 200 || response.status === 201) {
        onClose();
        onAddSatelliteNotification();
        setFormData(initialFormData);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    // ...rest of the form...
    <div className="fixed inset-0 z-10 overflow-y-auto" role="dialog">
      <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <form
          className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 text-slate-900"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          onSubmit={handleSubmit}
        >
          <h3 className="mb-3 text-lg font-bold leading-6 text-center text-gray-900">
            Create Satellite Notification
          </h3>
          <label>
            Satellite Code:
            <select
  name="satelliteId"
  value={formData.satelliteId}
  onChange={(e) => {
    setFormData({
      ...formData,
      satelliteId: Number(e.target.value),
    });
  }}
  className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
>
  <option value="">Select a satellite</option>
  {satellites.map((satellite, index) => (
    <option
      key={index}
      value={satellite.satelliteId}
    >
      {satellite.satelliteCode + " "}
      {satellite.satelliteName}
    </option>
  ))}
</select>

          </label>
          <label>
            First Name:
            <input
              name="firstName"
              type="text"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
            />
          </label>
          <label>
            Last Name:
            <input
              name="lastName"
              type="text"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
            />
          </label>
          <label>
            Email Address:
            <input
              name="emailAddress"
              type="email"
              value={formData.emailAddress}
              onChange={handleChange}
              className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
            />
          </label>
          <div className="mt-3 mb-3">
            <label>
              Positive Notification:
              <input
                name="isPositiveNotification"
                type="checkbox"
                checked={formData.isPositiveNotification}
                onChange={handleChange}
                className="ml-1"
              />
            </label>
          </div>
          <label>
            Percent Below:
            <input
              name="percentBelow"
              type="number"
              value={formData.percentBelow}
              onChange={handleChange}
              className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
            />
          </label>
          <label>
            Notification Title:
            <input
              name="notificationTitle"
              type="text"
              value={formData.notificationTitle}
              onChange={handleChange}
              className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
            />
          </label>
          <div className="flex mt-5 sm:mt-6">
            <button
              type="button"
              onClick={onClose}
              className="inline-flex justify-center w-full px-4 py-2 mr-2 text-base font-medium leading-6 text-black transition duration-150 ease-in-out border border-transparent rounded-md shadow-sm bg-slate-200 hover:bg-slate-300 focus:outline-none focus:border-slate-500 focus:shadow-outline-red sm:text-sm sm:leading-5"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading} // Disable button when loading
              className={`inline-flex justify-center w-full px-4 py-2 mx-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm sm:text-sm ${
                isLoading
                  ? "bg-sky-500"
                  : "bg-sky-600 hover:bg-sky-700 focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              }`}
            >
              {isLoading ? (
                <>
                  <svg
                    className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Loading...
                </>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

interface AddSatelliteNotificationButtonProps {
  companyId: any;
  userId: any;
  onAddSatelliteNotification: () => void;
}

const AddSatelliteNotificationButton = ({
  companyId,
  userId,
  onAddSatelliteNotification,
}: AddSatelliteNotificationButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  return (
    <>
      <button
        onClick={handleOpenModal}
        className="inline-flex items-center px-2 py-1 my-2 text-xs font-medium text-white border border-transparent rounded-md shadow-sm bg-sky-500 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:px-4 sm:py-2 sm:text-sm"
      >
        Add Satellite Notification
      </button>

      <AddSatelliteNotificationModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        companyId={companyId}
        userId={userId}
        onAddSatelliteNotification={onAddSatelliteNotification}
      />
    </>
  );
};

export default AddSatelliteNotificationButton;
