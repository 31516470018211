import {useLocation} from "react-router";
import React, {useEffect} from "react";
import ReactGA from "react-ga";

const RouteTracker = () =>{
    const location = useLocation()
    useEffect(() => {
        switch (location.pathname){
            case '/serviceexperts':
                ReactGA.pageview(location.pathname, [
                    'masterTracker', 'four'
                ]);
                break;
            case '/c/hv':
                ReactGA.pageview(location.pathname, [
                    'masterTracker', 'two'
                ]);
                break;
            case '/admin/dashboard':
                ReactGA.pageview(location.pathname, [
                    'five'
                ]);
                break;
        }
    }, [location]);
    return <></>;
}
export default RouteTracker;