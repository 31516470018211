import axios from "axios";
import { ChangeEvent, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { SatelliteContext } from "src/pages/admin/satelliteContext";
import {
  getClients,
  getCompanies,
} from "../../../shared/services/internal-user-service";

interface SelectComponentProps {
  items: { value: number; text: string }[];
  defaultText: string;
  onSelectChange: (value: number) => void;
}

const SelectComponent = ({
  items,
  defaultText,
  onSelectChange,
}: SelectComponentProps) => {
  const [selected, setSelected] = useState<number>(0);

  useEffect(() => {
    if (items && items.length === 1) {
      setSelected(items[0].value);
      onSelectChange(items[0].value);
    }
  }, [items]);

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    let newValue = parseInt(event.target.value);
    setSelected(newValue);
    onSelectChange(newValue);
  };

  return (
    <div className="flex flex-col content-start mt-2 mb-4 text-xs ml- lg:ml-6 lg:flex-row">
      <select
        className="w-full p-3 mx-1 mt-3 border rounded-xl bg-gray-50"
        name="select"
        value={selected}
        onChange={handleSelectChange}
      >
        <option value={0}>{defaultText}</option>
        {items?.map((item) => (
          <option key={`select${item.value}`} value={item.value}>
            {item.text}
          </option>
        ))}
      </select>
    </div>
  );
};

interface SatellitesDropdownsProps {
  changeCompanyId?: (value: number) => void;
  changeSatelliteId?: (value: number) => void;
}

const SatellitesDropdowns = (props: SatellitesDropdownsProps) => {
  const [client, setClient] = useState<number>(0);
  const [company, setCompany] = useState<number | null>(null);
  const [satellite, setSatellite] = useState<number>(0);
  const [clients, setClients] = useState<any[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [satellites, setSatellites] = useState<
    {
      satelliteId: string | number | readonly string[] | undefined;
      satelliteName: ReactNode;
      value: number;
      text: string;
    }[]
  >([]);
  const {
    selectedSatelliteId,
    setSelectedSatelliteId,
    selectedCompanyId,
    setSelectedCompanyId,
  } = useContext(SatelliteContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [clientResponse, companyResponse] = await Promise.all([
          getClients(),
          getCompanies(),
        ]);

        setClients(clientResponse);
        setCompanies(companyResponse);

        // Fetch satellites only when company id is neither 0 nor null
        if (company && company !== 0) {
          const response = await axios.get("/api/Satellite/by-company", {
            params: { companyId: company },
          });

          const newSatellites = response.data.map(
            (satellite: { satelliteId: any; satelliteName: any; satelliteCode :any }) => ({
              value: satellite.satelliteId,
              text: `${satellite.satelliteCode} ${satellite.satelliteName}`,
            })
          );
          setSatellites(newSatellites);
        } else {
          // Clear satellites list when company id is 0 or null
          setSatellites([]);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [company]);

  const handleCompanyChange = useCallback((value: number) => {
    setCompany(value);
    setSelectedCompanyId(value); 
    props.changeCompanyId && props.changeCompanyId(value);
  }, []);
  

  // function that handles the changes
  const handleSatelliteChange = (value: number) => {
    setSatellite(value);
    props.changeSatelliteId && props.changeSatelliteId(value);
    setSelectedSatelliteId(value);
  };

  return (
    <>
      <div className="flex space-x-2 overflow-x-auto">
        <SelectComponent
          items={companies}
          defaultText="--Run for all---"
          onSelectChange={handleCompanyChange}
        />
        <SelectComponent
          items={satellites}
          defaultText="Run For All"
          onSelectChange={handleSatelliteChange}
        />
      </div>
    </>
  );
};

export default SatellitesDropdowns;
